import { Outlet } from "react-router-dom";
import VerticalMenu from "./LeftMenu/VerticalMenu";
import Header from "./Header/Header";
import { useEffect, useState } from "react";
import { PlanPopup } from "./Common/PlanPopup/PlanPopup";
import WebService from "../Services/WebService";
import ChangePass from "./Common/ChangePasswordPopup/ChangePass";
import { Button, Modal } from "react-bootstrap";
import { t } from "i18next";
import Table from 'react-bootstrap/Table';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiCreditCard } from "react-icons/bi";
import moment from "moment";
import PaymentCards from "./Common/PaymentCards/PaymentCards";
import { useDispatch, useSelector } from "react-redux";
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from "../components/Chatbot/config";
import MessageParser from "../components/Chatbot/MessageParser";
import ActionProvider from "../components/Chatbot/ActionProvider";
import { aCE, aiM, oS, vCE } from "../Redux/actions";

const Main = () => {
  const dispatch = useDispatch();
  const idChatbotEnabled = useSelector((state: any) => state.aCE.aCE);
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  const userId = useSelector((state: any) => state.uI.id);
  const [show, setShow] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [transactionId, setTransactionId] = useState<any>();
  const [invoiceNo, setInvoiceNo] = useState<any>();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showPendingTransaction, setShowPendingTransaction] = useState<any>(false);
  const [transactionList, setTransactionList] = useState<any>([]);
  const [totalTransaction, setTotalTransaction] = useState<any>(0);
  const [loader, setLoader] = useState<any>(false);
  const [showPay, setShowPay] = useState<any>(false);
  const [showChatbot, setShowChatbot] = useState(false);
  const [planPrice, setPlanPrice] = useState<any>(0);


  useEffect(() => {
    getCurrentPlan();
    var user_id = userId;
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
      }
      else {
        if (user_id !== userId) {
          console.log("Close window due to user change");
          window.close();
        }
      }
    });

  }, [])


  const getCurrentPlan = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `subscription/current-subscription`,
      body: null,
      isShowError: false,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        dispatch(aiM(res?.result?.client?.aiModel?.aiModelName));
        dispatch(aCE(res?.result?.chatbotEnabled));
        if (res.result) {
          if (res.result.pendingTransaction.length > 0 && role !== "BASIC_USER") {
            setTransactionList(res.result.pendingTransaction)
            setShowPendingTransaction(true);
          } else if (res.result.paymentStatus === "PAYMENT_DUE" || res.result.paymentStatus === "PENDING" || res.result.paymentStatus === "EXPIRED" || (res.result.paymentStatus === "TRIAL" && !res.result.isCardAdded)) {
            setPaymentStatus(res.result.paymentStatus);
            setShow(true);
          } else {
            setPaymentStatus("PAID");
            setShow(false);
            meCall();
          }
          setPlanPrice(res.result.price);
          if (role === "COMPLIANCE_OFFICER") {
            dispatch(vCE(res.result?.client?.voiceCalls));
          }
        }
        setTransactionId(res?.result?.transaction?.id)
        setInvoiceNo(res?.result?.transaction?.invoiceNumber)
        setLoader(false);

      })
      .catch((error: any) => {
        setLoader(false);
        // return error;
      });
  };

  const meCall = () => {
    return WebService.getAPI({
      action: `me`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (role !== "COMPLIANCE_OFFICER") {
          dispatch(vCE(res?.client?.voiceCalls));
        }
        localStorage.setItem("pto", res.client?.aiModel?.aiModelName === "BERT" ? "B" : res.client?.aiModel?.aiModelName === "LLAMA3" ? "L" : "K");
        if (res.temporary_password === true) {
          setShowChangePassword(true);
          return false;
        }
        dispatch(oS(res.client?.otherService));
      })
      .catch((error) => {
        return error;
      });
  };



  return (
    <>
      <Header />
      <div id="main-app" className="layout-veritcle-menu">
        <VerticalMenu />
        <div className="app-body">
          {(paymentStatus === "PENDING" || paymentStatus === "PAYMENT_DUE" || paymentStatus === "TRIAL" || paymentStatus === "EXPIRED")
            && localStorage.getItem("cf") != "ad"
            ?
            <PlanPopup show={show} trxId={transactionId} invoiceNo={invoiceNo} paymentStatus={paymentStatus} planPrice={planPrice}  ></PlanPopup>
            :
            <Outlet />
          }
          {
            showChangePassword && localStorage.getItem("cf") != "ad" ?
              <ChangePass parentCallbck={(data: any) => setShowChangePassword(data)} /> : ""
          }
        </div>
      </div>
      {
        idChatbotEnabled &&
        <Button
          variant="primary"
          className="talk-to-agent-button"
          onClick={() => setShowChatbot(!showChatbot)}>
          <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="appChatbotButtonIcon_Lq7z"><path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path></svg>
        </Button>
      }


      {/* <Button
        variant="primary"
        className="talk-to-agent-button"
        onClick={() => setShowChatbot(!showChatbot)}>
        <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="appChatbotButtonIcon_Lq7z"><path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path></svg>
      </Button> */}

      {showChatbot && (
        <div className="App">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      )}


      {showPay ?
        <PaymentCards
          trxId={transactionId}
          action={""}
          show={showPay}
          type="PLAN"
          paymentStatus={paymentStatus}
          parentCallbackRefreshList={(data: any) => setShowPay(data)}
          parentCallbackIsClose={(data: any) => setShowPay(data)}
        />
        : ""}

      <Modal
        show={showPendingTransaction}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header >
          <Modal.Title>Payment Due</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="table-style">
            <thead>
              <tr>
                <th className="text-nowrap">Invoice No</th>
                <th className="text-nowrap">Duration</th>
                <th >Action</th>
              </tr>
            </thead>
            <tbody>
              {
                transactionList.map((ele: any, index: any) => {
                  return (
                    <tr>
                      <td className="text-nowrap">{ele.invoiceNumber}</td>
                      {ele.type === "ADDITIONAL_CHARGE" ? <td className="text-nowrap">-</td> : <td className="text-nowrap">{moment(ele.startDate).format('MM/DD/YYYY') + " - " +
                        moment(ele.endDate).format('MM/DD/YYYY')
                      }</td>}

                      <td className="">
                        <div className="action-btns text-center d-flex gap-2">
                          <Button
                            className="action-btn btn-view "
                            title="View"
                            onClick={() => window.open(ele.invoicePath)}
                          >
                            <MdOutlineRemoveRedEye className="icon" /> {t("Common.view")}
                          </Button>

                          <Button
                            className="action-btn btn-delete"
                            title="Pay"
                            onClick={() => {
                              setShowPay(true)
                              // setShow(true)
                              setTransactionId(ele.id)
                              setInvoiceNo(ele.invoiceNumber)
                              setPaymentStatus("PAYMENT_DUE")
                            }}
                          >
                            <BiCreditCard className="icon" />
                            <span style={{ marginLeft: "5px" }}>Pay</span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Main;

